import { AccessControl, HelpCircle } from '@opinary/ui-components'

import { getAccountDetails, getPermissions } from '../../api/auth'
import { HELP_CENTER_URL } from '../../api/constants.ts'
import { PERMISSIONS } from '../../constants.ts'
import { isToolUser } from '../../pages/Verify/constants.ts'
import CreatePoll from '../Create'
import { IconText } from '../index.ts'
import {
  NavList,
  NavWrapper,
  Section,
  SectionTitle,
  StyledLink,
} from './index.style'
import NavigationItem from './NavigationItem.tsx'
import { TRANSLATIONS } from './translations.ts'
import {
  conversionNavItems,
  getEngageNavItems,
  understandNavItems,
} from './utils.ts'

const Navigation = () => {
  const permissions = getPermissions()
  const accountDetails = getAccountDetails()
  const toolUser = isToolUser(accountDetails)
  const engageNavItems = getEngageNavItems(toolUser)

  return (
    <NavWrapper>
      <NavList>
        <CreatePoll />
        <Section>
          <SectionTitle>{TRANSLATIONS.ENGAGE_TITLE}</SectionTitle>
          {engageNavItems.map((navItem, index) => (
            <NavigationItem
              {...navItem}
              key={navItem.id}
              index={index}
              permissions={permissions}
            />
          ))}
        </Section>
        {toolUser && (
          <AccessControl
            permissions={permissions}
            validPermissions={[PERMISSIONS.AUDIENCE]}
          >
            <Section>
              <SectionTitle>{TRANSLATIONS.AUDIENCE_TITLE}</SectionTitle>
              {understandNavItems.map((navItem, index) => (
                <NavigationItem
                  {...navItem}
                  key={navItem.id}
                  index={index}
                  permissions={permissions}
                />
              ))}
            </Section>
          </AccessControl>
        )}
        <AccessControl
          permissions={permissions}
          validPermissions={[PERMISSIONS.CONVERSIONS, PERMISSIONS.ADMIN]}
        >
          <Section>
            <SectionTitle>{TRANSLATIONS.CONVERSION_TITLE}</SectionTitle>
            {conversionNavItems.map((navItem, index) => (
              <NavigationItem
                {...navItem}
                key={navItem.id}
                index={index}
                permissions={permissions}
              />
            ))}
          </Section>
        </AccessControl>
      </NavList>
      <StyledLink
        to={HELP_CENTER_URL}
        data-testid={'helpCenterLink'}
        target={'_blank'}
      >
        <IconText Icon={HelpCircle} text={'Help Center'} />
      </StyledLink>
    </NavWrapper>
  )
}

export default Navigation
